import { render, staticRenderFns } from "./DefaultPageHeader.vue?vue&type=template&id=6bcb55a0&"
import script from "./DefaultPageHeader.vue?vue&type=script&lang=ts&"
export * from "./DefaultPageHeader.vue?vue&type=script&lang=ts&"
import style0 from "./DefaultPageHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimateText: require('/tmp/build_d38f6897/src/components/animate/AnimateText.vue').default,SubPagesNavigation: require('/tmp/build_d38f6897/src/components/navigation/SubPagesNavigation.vue').default})
